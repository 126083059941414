
export const projectConstants = {
    GETPROJECTLIST_REQUEST: "GETPROJECT_LIST_REQUEST",
    GETPROJECTLIST_SUCCESS: "GETPROJECT_LIST_SUCCESS",
    GETPROJECTLIST_FAILURE: "GETPROJECT_LIST_FAILURE",

    SAVEPROJECT_REQUEST: "PROJECT_SAVE_REQUEST",
    SAVEPROJECT_SUCCESS: "PROJECT_SAVE_SUCCESS",
    SAVEPROJECT_FAILURE: "PROJECT_SAVE_FAILURE",

    PROJECTEDIT_REQUEST: "PROJECT_EDIT_REQUEST",
    PROJECTEDIT_SUCCESS: "PROJECT_EDIT_SUCCESS",
    PROJECTEDIT_FAILURE: "PROJECT_EDIT_FAILURE",

    PROJECTUPDATE_REQUEST: "PROJECT_UPATE_REQUEST",
    PROJECTUPDATE_SUCCESS: "PROJECT_UPDATE_SUCCESS",
    PROJECTUPDATE_FAILURE: "PROJECT_UPATE_FAILURE",
    

};  