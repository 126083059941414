export const adminDashboardConstants = {
    GETONLEAVELIST_REQUEST: "ONLEAVEL_LIST_REQUEST",
    GETONLEAVELIST_SUCCESS: "ONLEAVEL_LIST_SUCCESS",
    GETONLEAVELIST_FAILURE: "ONLEAVEL_LIST_FAILURE",

    GETANNIVERSARYLIST_REQUEST: "ANNIVERSARY_LIST_REQUEST",
    GETANNIVERSARYLIST_SUCCESS: "ANNIVERSARY_LIST_SUCCESS",
    GETANNIVERSARYLIST_FAILURE: "ANNIVERSARY_LIST_FAILURE",

    GETBIRTHDAYLIST_REQUEST: "BIRTHDAY_LIST_REQUEST",
    GETBIRTHDAYLIST_SUCCESS: "BIRTHDAY_LIST_SUCCESS",
    GETBIRTHDAYLIST_FAILURE: "BIRTHDAY_LIST_FAILURE",
};
