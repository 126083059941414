import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import { APIURL } from "../../../../../../constants/config";
import { priority } from "../../../../../../utilities/reportContent";
import {
  postCommit,
  getCommit,
  getTaskData,
  getUserListForReporting,
  updateTask,
  deleteTaskApiCall,
} from "../../Services/taskBoard.services";
import dateFormat from "dateformat";
import { useState, memo, useEffect, useMemo, useCallback } from "react";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";

// import TextEditor from "../../TextEditor/TextEditor";
const Comment = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [commitList, setCommitList] = useState([]);
  const [editData, setEditData] = useState();
  const [userOption, setUserOption] = useState([]);
  const [userListRawData, setuserListRawData] = useState([]);
  const [error, setError] = useState();
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [commit, setCommit] = useState({
    commentDesc: "",
    userId: user.data._id,
  });
  const toggle = () => props.setShow(!props.show);
  const [showOptions,setShowOptions]=useState(false);
  const [selectEmployee, setSelectEmployee] = useState([]);

  useEffect(() => {
    if (props.show) {
      getCommitData();
      TaskData();
      getUser();
    }
  }, [props.show]);

  const getUser = () => {
    getUserListForReporting()
      .then((response) => {
        let option = [];
        if (response?.data) {
          response?.data?.map((item) => {
            option.push({
              value: item._id,
              label: item.first_name + " " + item.last_name,
            });
          });
          setUserOption(option);
          setuserListRawData(response?.data);
        }
      })
      .catch((error) => {
        console.log("error Occure", error);
      });
  };
  const TaskData = () => {
    console.log("dsdsds");
    getTaskData({ taskId: props.taskID }).then((result) => {
      if (result.status == 200) {
        const {
          title,
          task_description,
          projectId,
          estimatedHours,
          hoursLeft,
          userId,
          status,
          priority,
          sprintId,
          createdBy,
          _id,
        } = result.data[0].tasks;
        setSelectEmployee( userId?.map((i) => {
            return {
              value: i._id,
              label: i.first_name + " " + i.last_name,
            };
          }));
      
        setEditData({
          title,
          task_description,
          userId:  userId?.map((i) => {
            return {
              value: i._id,
              label: i.first_name + " " + i.last_name,
            };
          }),
          projectId: projectId._id,
          estimatedHours,
          hoursLeft,
          status,
          priority,
          sprintId: sprintId._id,
          createdBy: createdBy._id,
          _id,
        });
      } else console.log("error", result);
    });
  };
  const getCommitData = () => {
    getCommit({ taskId: props.taskID }).then((result) => {
      if (result.status == 200) {
        setCommitList(result.data);
      }
    });
  };
  const handleClose = () => {
    //alert(1);
    props.setShow(false);
    setError("");
    //setEditData({ priority: priority[0].cat });
  };
  const commitData = () => {
    postCommit({ ...commit, taskId: props.taskID }).then((result) => {
      if (result.status == 200) {
        getCommitData();
        setCommit({ ...commit, commentDesc: "" });
      } else console.log("error", result);
    });
  };
  // editData&&console.log("====editData===",editData);

  const handleChange = (name, e) => {
    
     if (name == "priority")
      setEditData({ ...editData, priority: e[0].cat });
    else setEditData({ ...editData, [name]: e.target.value });
  };

  const showImageData = useMemo(() => {
    const uniqureId = editData?.userId?.map((i) => i.value);

    const ArrOfObj = [];
    userListRawData?.forEach((i) => {
      if (uniqureId?.includes(i._id)) ArrOfObj.push(i);
    });
    return ArrOfObj.reverse();

  }, [editData?.userId, userOption]);

  const saveData = () => {
    console.log(editData);
    updateTask(editData, props.taskModuleId).then((result) => {
      if (result.status == 200) {
        handleClose();
        props.getTask();
      } else {
        setError(result);
        console.log(9988, result);
      }
    });
  };
  // const deleteTask = () => {
  //   toggleNested();
  // };

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  const toggleAll = () => {
    deleteTaskApiCall({ _id: props.taskID }).then((result) => {
      if (result.status == 200) {
        setNestedModal(!nestedModal);
        setCloseAll(true);
        props.getTask();
      }
    });
  };

  const toPascalCase = (str) => {
    if (!str) return "";
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleInputChange = useCallback((typedOption) => {
    if (typedOption.trim().length > 2 ) {
      setShowOptions(true); 
    }
    else {
      setShowOptions(false);
    }
  }, []);

  const handleEmploye = (e) => {
    //var userId=e.map((itm)=>itm.value);
    //console.log("userIduserId",userId);
    setEditData({...editData,userId:e});
    setSelectEmployee(e);
  };

  return (
    <>
      <Modal isOpen={props.show} className="task_details" size="lg">
        <ModalHeader >
          <h5 className="modal-title">Edit Task</h5>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md={8} className="divider">
                <FormGroup>
                  <Label for="Title">Task Name</Label>
                  <Input
                    type="text"
                    placeholder="Enter Task"
                    value={editData?.title}
                    onChange={(e) => handleChange("title", e)}
                  />
                  {error?.title && (
                    <span className="text-danger">{error.title[0]}</span>
                  )}
                </FormGroup>
                {/* <div className="form-group">
                  <Label for="Task-Description">Task Description </Label>
                  <span className="text-capitalize">
                    <textarea
                      className="form-control"
                      placeholder="Enter Description"
                      rows="5"
                      value={editData?.task_description}
                      onChange={(e) => handleChange("task_description", e)}
                    ></textarea>
                  </span>
                </div> */}
                <Row className="my-4">
                  <Col md={8}>
                    <Input
                      id="Title"
                      onChange={(e) => {
                        setCommit({ ...commit, commentDesc: e.target.value });
                      }}
                      value={commit?.commentDesc}
                      placeholder="Add comment"
                    ></Input>
                    <br/>
                    {/* <TextEditor
                    setCommit={setCommit}
                    /> */}
                  </Col>
                  <Col md={4} className="text-right">
                    <Button
                      className="btn btn-block btn-primary"
                      onClick={commitData}
                    >
                      {" "}
                      Add{" "}
                    </Button>
                  </Col>
                </Row>
                <hr />
                <div className="comment_section">
                  {commitList.map((item, index) => (
                    <div className="d-flex align-items-top my-3" key={index}>
                      <div>
                        <span className="usernm">
                          {item.taskcomments.userId?.profileImage ? (
                            <OverlayTrigger
                              placement={"top"}
                              key={index}
                              overlay={
                                <Tooltip id="tooltip-top">
                                  {item.taskcomments.userId?.first_name +
                                    " " +
                                    item.taskcomments.userId?.last_name}
                                </Tooltip>
                              }
                            >
                              <img
                                src={
                                  APIURL +
                                  "./assets/profileImages/" +
                                  item.taskcomments.userId?.profileImage
                                }
                                alt={
                                  item.taskcomments.userId?.first_name +
                                  " " +
                                  item.taskcomments.userId?.last_name
                                }
                                className="mr-2"
                              />
                            </OverlayTrigger>
                          ) : (
                            <span className="avtar">
                              {item.taskcomments.userId.first_name?.slice(
                                0,
                                1
                              ) +
                                item.taskcomments.userId.last_name?.slice(0, 1)}
                            </span>
                          )}
                        </span>
                      </div>
                      {/*logo img icon */}
                      <div>
                        <div className="small mb-2 d-flex">
                          <h6 className="mr-3 noticelist__cnt--nm">
                            {item.taskcomments.userId.first_name +
                              " " +
                              item.taskcomments.userId.last_name}
                          </h6>
                          <span>
                            {dateFormat(item.taskcomments.time, "fullDate")}
                            {", "}
                            {dateFormat(item.taskcomments.time, "h:MM:ss TT")}
                          </span>
                        </div>
                        <p>{item.taskcomments.commentDesc}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="row CommitListData">
            {commitList.map((item, index) => (
              <div key={index} className="col-12 commitDataList">
                <div className="d-flex align-items-top my-3">
                  <div>
                    <span className="usernm">
                      {item.taskcomments.userId?.profileImage ? (
                        <OverlayTrigger
                          placement={"top"}
                          key={index}
                          overlay={
                            <Tooltip id="tooltip-top">
                              {item.taskcomments.userId?.first_name +
                                " " +
                                item.taskcomments.userId?.last_name}
                            </Tooltip>
                          }
                        >
                          <img
                            src={
                              APIURL +
                              "./assets/profileImages/" +
                              item.taskcomments.userId?.profileImage
                            }
                            alt={
                              item.taskcomments.userId?.first_name +
                              " " +
                              item.taskcomments.userId?.last_name
                            }
                            className="mr-2"
                          />
                        </OverlayTrigger>
                      ) : (
                        <span className="avtar">
                          {item.taskcomments.userId.first_name?.slice(0, 1) +
                            item.taskcomments.userId.last_name?.slice(0, 1)}
                        </span>
                      )}
                    </span>
                  </div>
                  <div>
                    <div className="small mb-2">
                      <strong className="mr-3">
                        {item.taskcomments.userId.first_name +
                          " " +
                          item.taskcomments.userId.last_name}
                      </strong>
                      <span>
                        {dateFormat(item.taskcomments.time, "fullDate")}
                        {", "}
                        {dateFormat(item.taskcomments.time, "h:MM:ss TT")}
                      </span>
                    </div>
                    <p>{item.taskcomments.commentDesc}</p>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
              </Col>
              <div className="col-4">
                <div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <Label>Estimated Hrs</Label>
                        <Input
                          type="number"
                          min="0"
                          value={editData?.estimatedHours}
                          onChange={(e) => handleChange("estimatedHours", e)}
                        />
                        <span className="text-danger">{}</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <Label>Time spent</Label>
                        <Input
                          type="number"
                          min="0"
                          value={editData?.hoursLeft}
                          onChange={(e) => handleChange("hoursLeft", e)}
                        />
                        {/* {error?.common && (
                          <span className="text-danger">{error.common[0]}</span>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Label>Priority</Label>
                    <div>
                      <FormGroup>
                        <Multiselect
                          id="priority"
                          displayValue="key"
                          options={priority}
                          singleSelect
                          selectedValues={[
                            {
                              key: toPascalCase(editData?.priority),
                              cat: toPascalCase(editData?.priority),
                            },
                          ]}
                          onSelect={(item) => {
                            handleChange("priority", item);
                          }}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div>
                    <Label>Assign</Label>
                    <FormGroup>
                      <Select
                        isMulti
                        onChange={(e) => handleEmploye(e)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Type to search"
                        value={selectEmployee}
                        options={showOptions?userOption:[]}
                        noOptionsMessage={()=>showOptions?"No options" : null}
                        onInputChange = { handleInputChange }
                      />
                      <span className="d-block mt-1 text-danger text-capitalize"></span>
                    </FormGroup>
                    <div className="user_assign">
                      {showImageData.length
                        ? showImageData?.map((profile, index) => (
                            <OverlayTrigger
                              placement={"top"}
                              key={index}
                              overlay={
                                <Tooltip id="tooltip-top">
                                  {profile?.first_name +
                                    " " +
                                    profile?.last_name}
                                </Tooltip>
                              }
                            >
                              <div key={index}>
                                {profile?.profileImage ? (
                                  <a href="#">
                                    <img
                                      src={
                                        APIURL +
                                        "./assets/profileImages/" +
                                        profile?.profileImage
                                      }
                                      alt={
                                        profile?.first_name +
                                        " " +
                                        profile.last_name
                                      }
                                    />
                                  </a>
                                ) : (
                                  <div className="avtar ">
                                    {profile?.first_name?.slice(0, 1) +
                                      profile?.last_name?.slice(0, 1)}
                                  </div>
                                )}
                              </div>
                            </OverlayTrigger>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            
            {/* <div>
              <div>
                <h6>Comment</h6>
                <textarea
                  className="form-control"
                  onChange={(e) => {
                    setCommit({ ...commit, commentDesc: e.target.value });
                  }}
                  value={commit?.commentDesc}
                  placeholder="Comment"
                  rows={3}
                ></textarea>
              </div>
              <div className="d-flex justify-content-end">
                <Button color="primary mt-2" onClick={commitData}>
                  Send
                </Button>
              </div>
            </div> */}
          </Form>
          {/* <div className="pb-1 pt-1 commitHeading mt-3">
            <h5>Comments</h5>
          </div> */}
          {/* <div className="row CommitListData">
            {commitList.map((item, index) => (
              <div key={index} className="col-12 commitDataList">
                <div className="d-flex align-items-top my-3">
                  <div>
                    <span className="usernm">
                      {item.taskcomments.userId?.profileImage ? (
                        <OverlayTrigger
                          placement={"top"}
                          key={index}
                          overlay={
                            <Tooltip id="tooltip-top">
                              {item.taskcomments.userId?.first_name +
                                " " +
                                item.taskcomments.userId?.last_name}
                            </Tooltip>
                          }
                        >
                          <img
                            src={
                              APIURL +
                              "./assets/profileImages/" +
                              item.taskcomments.userId?.profileImage
                            }
                            alt={
                              item.taskcomments.userId?.first_name +
                              " " +
                              item.taskcomments.userId?.last_name
                            }
                            className="mr-2"
                          />
                        </OverlayTrigger>
                      ) : (
                        <span className="avtar">
                          {item.taskcomments.userId.first_name?.slice(0, 1) +
                            item.taskcomments.userId.last_name?.slice(0, 1)}
                        </span>
                      )}
                    </span>
                  </div>
                  <div>
                    <div className="small mb-2">
                      <strong className="mr-3">
                        {item.taskcomments.userId.first_name +
                          " " +
                          item.taskcomments.userId.last_name}
                      </strong>
                      <span>
                        {dateFormat(item.taskcomments.time, "fullDate")}
                        {", "}
                        {dateFormat(item.taskcomments.time, "h:MM:ss TT")}
                      </span>
                    </div>
                    <p>{item.taskcomments.commentDesc}</p>
                  </div>
                </div>
              </div>
            ))}
          </div> */}

          {/* Confirmation Model on Delete Task */}
          <Modal
            isOpen={nestedModal}
            toggle={toggleNested}
            onClosed={closeAll ? toggle : undefined}
          >
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>
              <h5>Are you sure you want to delete this Task ?</h5>
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleNested}>Cancel</Button>
              <Button color="danger" onClick={toggleAll}>
                Yes
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            
            {/* <Button color="danger mr-2" onClick={deleteTask}> Delete </Button> */}
          </div>
          <Button className="btn btn-default mr-3" onClick={handleClose}>
            {" "}
            Cancel{" "}
          </Button>
          <Button color="success"  onClick={saveData}>
              {" "}
              Save{" "}
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default memo(Comment);
