export const regularizationConstants = {
    GETALLREGULARIZATION_REQUEST: "REGULARIZATION_GETALL_REQUEST",
    GETALLREGULARIZATION_SUCCESS: "REGULARIZATION_GETALL_SUCCESS",
    GETALLREGULARIZATION_FAILURE: "REGULARIZATION_GETALL_FAILURE",

    REGULARIZATIONOUT_REQUEST: "REGULARIZATION_INOUT_REQUEST",
    REGULARIZATIONOUT_SUCCESS: "REGULARIZATION_INOUT_SUCCESS",
    REGULARIZATIONOUT_FAILURE: "REGULARIZATION_INOUT_FAILURE",

    REGULARIZATIONCLOCKCHECK_REQUEST: "REGULARIZATION_CLOCKCHECK_REQUEST",
    REGULARIZATIONCLOCKCHECK_SUCCESS: "REGULARIZATION_CLOCKCHECK_SUCCESS",
    REGULARIZATIONCLOCKCHECK_FAILURE: "REGULARIZATION_CLOCKCHECK_FAILURE",

    GETREGULARIZATIONBYDATE_REQUEST: "REGULARIZATION_BYDATE_REQUEST",
    GETREGULARIZATIONBYDATE_SUCCESS: "REGULARIZATION_BYDATE_SUCCESS",
    GETREGULARIZATIONBYDATE_FAILURE: "REGULARIZATION_BYDATE_FAILURE",
    
    PENDINGREQUEST_REQUEST: "PENDINGREQUEST_BYDATE_REQUEST",
    PENDINGREQUEST_SUCCESS: "PENDINGREQUEST_BYDATE_SUCCESS",
    PENDINGREQUEST_FAILURE: "PENDINGREQUEST_BYDATE_FAILURE",

    UPDATEREGULARIZATION_REQUEST: "UPDATEREQUEST_STATUS_REQUEST",
    UPDATEREGULARIZATION_SUCCESS: "UPDATEREQUEST_STATUS_SUCCESS",
    UPDATEREGULARIZATION_FAILURE: "UPDATEREQUEST_STATUS_FAILURE",

    GETATTENDANCEDETAIL_REQUEST: "GETATTENDANCE_DETAIL_REQUEST",
    GETATTENDANCEDETAIL_SUCCESS: "GETATTENDANCE_DETAIL_SUCCESS",
    GETATTENDANCEDETAIL_FAILURE: "GETATTENDANCE_DETAIL_FAILURE",

    REGULARIZATIONCOUNT_REQUEST: "REGULARIZATION_COUNT_REQUEST",
    REGULARIZATIONCOUNT_SUCCESS: "REGULARIZATION_COUNT_SUCCESS",
    REGULARIZATIONCOUNT_FAILURE: "REGULARIZATION_COUNT_FAILURE"
 
    
};
