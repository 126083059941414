
export const adminRegularizationConstants = { 
    PENDINGREQUEST_REQUEST: "PENDINGREQUEST_BYDATE_REQUEST",
    PENDINGREQUEST_SUCCESS: "PENDINGREQUEST_BYDATE_SUCCESS",
    PENDINGREQUEST_FAILURE: "PENDINGREQUEST_BYDATE_FAILURE",

    UPDATEREGULARIZATION_REQUEST: "UPDATEREQUEST_STATUS_REQUEST",
    UPDATEREGULARIZATION_SUCCESS: "UPDATEREQUEST_STATUS_SUCCESS",
    UPDATEREGULARIZATION_FAILURE: "UPDATEREQUEST_STATUS_FAILURE",

    GETATTENDANCEDETAIL_REQUEST: "GETATTENDANCE_DETAIL_REQUEST",
    GETATTENDANCEDETAIL_SUCCESS: "GETATTENDANCE_DETAIL_SUCCESS",
    GETATTENDANCEDETAIL_FAILURE: "GETATTENDANCE_DETAIL_FAILURE"
 
    
};
