import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  deleteModule,
  deleteTaskApiCall,
} from "../../Services/taskBoard.services";
import { memo, useState } from "react";

function ConfimationModel(props) {
  const [isLoading, setLoading] = useState(false);

  const deleteModuleBox = () => {
    setLoading(true);
    deleteModule(props.moduleId).then((result) => {
      if (result.status == 200) {
        handleClose();
        props.refresh();
      } else console.log("error", result);
      setLoading(false);
    });
  };

  const handleClose = () => {
    props.setDeleteConfirm(false);
  };

  const deleteTask = () => {
    setLoading(true);
    deleteTaskApiCall({ _id: props.taskId._id }).then((result) => {
      if (result.status == 200) {
        handleClose();
        props.getTask();
      }
      setLoading(false);
    });
  };

  return (
    <>
      <Modal show={props.deleteConfirm} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete {props.taskId?.delete} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this {props.taskId?.delete} ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={
              props.taskId?.delete == "Task" ? deleteTask : deleteModuleBox
            }
            disabled={isLoading}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(ConfimationModel);
