export const userConstants = {
    USERADD_REQUEST: "USER_ADD_REQUEST",
    USERADD_SUCCESS: "USER_ADD_SUCCESS",
    USERADD_FAILURE: "USER_ADD_FAILURE",

    USEREDIT_REQUEST: "USER_EDIT_REQUEST",
    USEREDIT_SUCCESS: "USER_EDIT_SUCCESS",
    USEREDIT_FAILURE: "USER_EDIT_FAILURE",

    USERUPDATE_REQUEST: "USER_UPDATE_REQUEST",
    USERUPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
    USERUPDATE_FAILURE: "USER_UPDATE_FAILURE",

    RESETPASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESETPASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESETPASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
    
    LOGOUT: "USERS_LOGOUT",

    FORGOTPASSWORD_REQUEST: "USERS_FORGOTPASSWORD_REQUEST",
    FORGOTPASSWORD_SUCCESS: "USERS_FORGOTPASSWORD_SUCCESS",
    FORGOTPASSWORD_FAILURE: "USERS_FORGOTPASSWORD_FAILURE",

    GETALLUSERS_REQUEST: "USERS_GETALL_REQUEST",
    GETALLUSERS_SUCCESS: "USERS_GETALL_SUCCESS",
    GETALLUSERS_FAILURE: "USERS_GETALL_FAILURE",

    DELETE_REQUEST: "USER_DELETE_REQUEST",
    DELETE_SUCCESS: "USER_DELETE_SUCCESS",
    DELETE_FAILURE: "USER_DELETE_FAILURE",  
    
    GETALLUSERS_REPORTING_LIST_REQUEST: "USERS_GETALL_REPORTING_LIST_REQUEST",
    GETALLUSERS_REPORTING_LIST_SUCCESS: "USERS_GETALL_REPORTING_LIST_SUCCESS",
    GETALLUSERS_REPORTING_LIST_FAILURE: "USERS_GETALL_REPORTING_LIST_FAILURE",

    GETALLUSERS_ATTENDANCE_REPORTING_LIST_REQUEST: "USERS_GETALL_ATTENDANCE_REPORTING_LIST_REQUEST",
    GETALLUSERS_ATTENDANCE_REPORTING_LIST_SUCCESS: "USERS_GETALL_ATTENDANCE_REPORTING_LIST_SUCCESS",
    GETALLUSERS_ATTENDANCE_REPORTING_LIST_FAILURE: "USERS_GETALL_ATTENDANCE_REPORTING_LIST_FAILURE",

};
