export const departmentConstants = {
    DEPARTMENTADD_REQUEST: "DEPARTMENT_ADD_REQUEST",
    DEPARTMENTADD_SUCCESS: "DEPARTMENT_ADD_SUCCESS",
    DEPARTMENTADD_FAILURE: "DEPARTMENT_ADD_FAILURE",

    DEPARTMENTEDIT_REQUEST: "DEPARTMENT_EDIT_REQUEST",
    DEPARTMENTEDIT_SUCCESS: "DEPARTMENT_EDIT_SUCCESS",
    DEPARTMENTEDIT_FAILURE: "DEPARTMENT_EDIT_FAILURE",

    DEPARTMENTUPDATE_REQUEST: "DEPARTMENT_UPDATE_REQUEST",
    DEPARTMENTUPDATE_SUCCESS: "DEPARTMENT_UPDATE_SUCCESS",
    DEPARTMENTUPDATE_FAILURE: "DEPARTMENT_UPDATE_FAILURE",

    GETALLDEPARTMENTS_REQUEST: "DEPARTMENTS_GETALL_REQUEST",
    GETALLDEPARTMENTS_SUCCESS: "DEPARTMENTS_GETALL_SUCCESS",
    GETALLDEPARTMENTS_FAILURE: "DEPARTMENTS_GETALL_FAILURE",

    
};
