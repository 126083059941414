import React, { Component } from "react";

export default class Footer extends Component {
    constructor(){
        super();
        this.state = {
            stylePath:true
        };
    }
    componentDidMount(){
          
    }
    render() {
        return (
            <footer className="text-center">
                
            </footer>	
        );
    }
}
