//export const APIURL = "http://devapi-lavender.imenso.in:5001/";
// export const APIURL = "https://api-lavender.imenso.in:5001/";
// export const BASEURL = "https://devlavender.imenso.in/";

//export const APIURL = "https://api-hrm.imenso.in:5016/";
//export const BASEURL = "https://hrm.imenso.in/";

export const APIURL = "https://apiultron.imenso.in:5011/";

export const BASEURL = "http://ultron-dev.imenso.in/";




