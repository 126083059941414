export const adminLeaveConstants = {
    GETLEAVELIST_REQUEST: "LEAVE_LIST_REQUEST",
    GETLEAVELIST_SUCCESS: "LEAVE_LIST_SUCCESS",
    GETLEAVELIST_FAILURE: "LEAVE_LIST_FAILURE",


    SENDLEAVE_REQUEST: "LEAVE_ SENDLEAVE_REQUEST",
    SENDLEAVE_SUCCESS: "LEAVE_ SENDLEAVE_SUCCESS",
    SENDLEAVE_FAILURE: "LEAVE_ SENDLEAVE_FAILURE",

    UPDATELEAVE_REQUEST: "LEAVE_UPDATELEAVE_REQUEST",
    UPDATELEAVE_SUCCESS: "LEAVE_UPDATELEAVE_SUCCESS",
    UPDATELEAVE_FAILURE: "LEAVE_UPDATELEAVE_FAILURE",

    EDITLEAVE_REQUEST: "LEAVE_EDITLEAVE_REQUEST",
    EDITLEAVE_SUCCESS: "LEAVE_EDITLEAVE_SUCCESS",
    EDITLEAVE_FAILURE: "LEAVE_EDITLEAVE_FAILURE",

    DELETELEAVE_REQUEST: "LEAVE_ DELETELEAVE_REQUEST",
    DELETELEAVE_SUCCESS: "LEAVE_ DELETELEAVE_SUCCESS",
    DELETELEAVE_FAILURE: "LEAVE_ DELETELEAVE_FAILURE",

    STATUSUPDATELEAVE_REQUEST: "LEAVE_STATUSUPDATELEAVE_REQUEST",
    STATUSUPDATELEAVE_SUCCESS: "LEAVE_SATUSUPDATELEAVE_SUCCESS",
    STATUSUPDATELEAVE_FAILURE: "LEAVE_STATUSUPDATELEAVE_FAILURE",

    GETLEAVELIST_REQUESTLIST_REQUEST_REQUEST: "LEAVE_REQUEST_LIST_REQUEST",
    GETLEAVELIST_REQUESTLIST_REQUEST_SUCCESS: "LEAVE_REQUEST_LIST_SUCCESS",
    GETLEAVELIST_REQUESTLIST_REQUEST_FAILURE: "LEAVE_REQUEST_LIST_FAILURE",

    CHECKCASUAL_LEAVES_REQUEST: "CHECK_CASUAL_LEAVES_REQUEST",
    CHECKCASUAL_LEAVES_SUCCESS: "CHECK_CASUAL_LEAVES_SUCCESS",
    CHECKCASUAL_LEAVES_FAILURE: "CHECK_CASUAL_LEAVES_FAILURE",
    
    CHECKSICK_LEAVES_REQUEST: "CHECK_SICK_LEAVES_REQUEST",
    CHECKSICK_LEAVES_SUCCESS: "CHECK_SICK_LEAVES_SUCCESS",
    CHECKSICK_LEAVES_FAILURE: "CHECK_SICK_LEAVES_FAILURE",

    
    PENDINGLEAVE_REQUEST: "PENDING_LEAVE_REQUEST",
    PENDINGLEAVE_SUCCESS: "PENDING_LEAVE_SUCCESS",
    PENDINGLEAVE_FAILURE: "PENDING_LEAVE_FAILURE",

    PENDINGLEAVECOUNTBYUSERID_REQUEST: "PENDING_LEAVE_COUNTBYUSERID_REQUEST",
    PENDINGLEAVECOUNTBYUSERID_SUCCESS: "PENDING_LEAVE_COUNTBYUSERID_SUCCESS",
    PENDINGLEAVECOUNTBYUSERID_FAILURE: "PENDING_LEAVE_COUNTBYUSERID_FAILURE",

};
