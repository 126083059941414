export const attendanceConstants = {
    GETALLATTENDANCE_REQUEST: "ATTENDANCE_GETALL_REQUEST",
    GETALLATTENDANCE_SUCCESS: "ATTENDANCE_GETALL_SUCCESS",
    GETALLATTENDANCE_FAILURE: "ATTENDANCE_GETALL_FAILURE",

    ATTENDANCEINOUT_REQUEST: "ATTENDANCE_INOUT_REQUEST",
    ATTENDANCEINOUT_SUCCESS: "ATTENDANCE_INOUT_SUCCESS",
    ATTENDANCEINOUT_FAILURE: "ATTENDANCE_INOUT_FAILURE",

    ATTENDANCECLOCKCHECK_REQUEST: "ATTENDANCE_CLOCKCHECK_REQUEST",
    ATTENDANCECLOCKCHECK_SUCCESS: "ATTENDANCE_CLOCKCHECK_SUCCESS",
    ATTENDANCECLOCKCHECK_FAILURE: "ATTENDANCE_CLOCKCHECK_FAILURE",

    GETATTENDANCEBYDATE_REQUEST: "ATTENDANCE_BYDATE_REQUEST",
    GETATTENDANCEBYDATE_SUCCESS: "ATTENDANCE_BYDATE_SUCCESS",
    GETATTENDANCEBYDATE_FAILURE: "ATTENDANCE_BYDATE_FAILURE",

    REGULARIZE_BYDATE_REQUEST: "REGULARIZE_BYDATE_REQUEST",
    REGULARIZE_BYDATE_SUCCESS: "REGULARIZE_BYDATE_SUCCESS",
    REGULARIZE_BYDATE_FAILURE: "REGULARIZE_BYDATE_FAILURE",


    CHECKREGULARIZE_BYDATE_REQUEST: "CHECKREGULARIZE_BYDATE_REQUEST",
    CHECKREGULARIZE_BYDATE_SUCCESS: "CHECKREGULARIZE_BYDATE_SUCCESS",
    CHECKREGULARIZE_BYDATE_FAILURE: "CHECKREGULARIZE_BYDATE_FAILURE"
 
    
};
